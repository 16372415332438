
import { Http } from "@/http"
import { Id, res_list, req_list } from '@/types/global'

export interface add_goods{
    name:string
    cover:string|Array<String>
    type_clothing:string,
    service_scope:string,
    number:number,
    content:string,
    price:number,
    stock:string,
    status:number,
    sales:number,
    goods_type:number,
    gold:string|number,
    diamond:string|number
}

export interface skill_data{
    begin:string
    end:string
    price:number
}

export interface share_data{
    start_amount:number
    amount:number
    end:string
    begin:string
}

export interface scheme{
    name:string
    person_limit:number
    make_amount:number
    content:string
}
export interface goods extends add_goods{
    id:Id
    start_time?:string|null
    fish_time?:string|null
}

class Httpgoods extends Http{
	get_list = (data:req_list) => {
		return this.get<res_list<goods>>({...data})
	}

    get_info = (id:number) => {
        return this.get<goods>({},`/admin/goods/${id}/`)
    }

    create = (data:add_goods) => {
        return this.post(data)
    }

    edit = ({id,...data}: goods) => {
        return this.patch(data,`/admin/goods/${id}/`)
    }

    del_one = (id:number) => {
        return this.delete(id)
    }
}

export const api_goods =  new Httpgoods('/admin/goods/')
