













































import { Vue, Component, Watch, Prop, Emit } from "vue-property-decorator";
@Component
export default class extends Vue {
  @Prop({
    required: true,
    type: String,
  })
  readonly title!: string;
  @Prop({
    required: false,
    type: String,
  })
  readonly panel!: string;
  update_time: [Date, Date] | null = null;
  @Emit("update-range")
  setCreateTimeRange(rangeType: string) {
    let startDate = new Date(),
      endDate = new Date();
    switch (rangeType) {
      case "今日":
        startDate = new Date();
        endDate = new Date();
        break;
      case "昨日":
        startDate = new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000);
        endDate = new Date(startDate.getTime() + 23 * 60 * 60 * 1000);
        break;
      case "最近7天":
        startDate = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
        endDate = new Date();
        break;
      case "最近30天":
        startDate = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000);
        endDate = new Date();
        break;
      default:
        break;
    }
    this.update_time = [startDate, endDate];
    return [
      this.formatDateToString(startDate),
      this.formatDateToString(endDate),
    ];
  }
  @Emit("update-range")
  onDateRangeChange(selectedDates: [Date, Date]) {
    console.log(selectedDates);
    this.update_time = selectedDates;
    return [
      this.formatDateToString(selectedDates[0]),
      this.formatDateToString(selectedDates[1]),
    ];
  }
  formatDateToString(dateString: Date) {
    // 创建一个新的Date对象
    var dateObject = new Date(dateString);

    // 提取年、月、日并格式化为"YYYY-MM-DD"
    var year = dateObject.getFullYear();
    var month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
    var day = ("0" + dateObject.getDate()).slice(-2);

    // 返回格式化后的日期字符串
    return year + "-" + month + "-" + day;
  }
  clearDates() {
    this.update_time = null;
  }
}
